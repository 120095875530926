<template>
    <div class="procedureAnnex" v-loading="loading">
        <div class="annexDataHeader">
            <nav>
                <div class="Route">
                    <a @click="backTop()">全部资料</a>
                    <template v-for="(item, index) in fileHierarchy">
                        <span :key="index" @click="returnDirectory(item.id, index, item)">
                            <i class="el-icon-arrow-right"></i>
                            {{ item.data }}
                        </span>
                    </template>
                </div>
            </nav>
            <div class="operationArea">
                <div class="fileBtn">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="上传"
                        placement="bottom"
                        v-if="!currentViewData.businessFileGroupConfig.IsRead && currentViewData.businessFileGroupConfig.IsAllowUpload"
                    >
                        <!-- <uploading
                            ref="uploading"
                            bu-code="Flow"
                            class="uploadfile"
                            v-if="!currentViewData.businessFileGroupConfig.IsRead && currentViewData.businessFileGroupConfig.IsAllowUpload"
                            :businessid="businessIId"
                            :parentid="ParentID"
                            :file-hierarchy="fileHierarchy"
                            @uploadFirm="uploadFirm"
                        ></uploading> -->
                        <el-upload
                            action=""
                            class="upload_btn"
                            :http-request="getfile"
                            :show-file-list="false"
                            multiple
                        >
                            <i class="iconfont iconshangchuan1"></i>
                        </el-upload>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="下载"
                        placement="bottom"
                    >
                        <button class="iconfont iconxiazai2" @click="batchDownload"></button>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="添加"
                        placement="bottom"
                        v-if="!currentViewData.businessFileGroupConfig.IsRead && currentViewData.businessFileGroupConfig.IsAllowCreateFolder"
                    >
                        <button class="iconfont icontianjia2" @click="AddFile"></button>
                    </el-tooltip>
                </div>
                <div class="plannedSpeed">
                    <el-progress
                        color="#0078FB"
                        define-back-color="#DBECFF"
                        text-color="#0078FB"
                        status="success"
                        v-if="isProgress"
                        :percentage="progressPercent"
                    ></el-progress>
                </div>
            </div>
        </div>
        <div class="annexList">
            <div class="AnnexInfo" id="AnnexInfoContainer" options="Setting">
                <table>
                    <thead v-if="isAdd">
                        <tr class="tebleFome">
                            <th style="width: 0.75rem">
                                <svg class="iconwenjianjia1" aria-hidden="true">
                                    <use xlink:href="#iconwenjianjia1"></use>
                                </svg>
                            </th>
                            <td colspan="2">
                                <div>
                                    <input
                                        type="text"
                                        v-model="newFile.FileName"
                                        class="form-control"
                                        placeholder="请输入文件夹名称"
                                    >
                                    <button class="el-icon-check" title="确认添加" @click="confirmAdd()"></button>
                                    <button class="el-icon-close" title="取消添加" @click="cancelAdd()"></button>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="fileData && fileData.length>0">
                            <tr v-for="(item, index) in fileData" :key="index" @click="seeFile(item)">
                                <th style="width: 0.75rem">
                                    <svg v-if="item.FileType == 1" class="iconwenjianjia1" aria-hidden="true">
                                        <use xlink:href="#iconwenjianjia1"></use>
                                    </svg>
                                    <svg v-if="item.FileType == 2" class="iconwenjian" aria-hidden="true">
                                        <use xlink:href="#iconwenjian"></use>
                                    </svg>
                                </th>
                                <td>
                                    <h4
                                        ref="content"
                                        class="fileName"
                                        :title="item.FileName"
                                        @blur="preservationName($event, index, item)"
                                    >
                                        {{ item.FileName }}
                                    </h4>
                                    <p class="fileTime">
                                        {{ item.CreateDate }}
                                    </p>
                                </td>
                                <td style="width: 1.5rem">
                                    <div class="fileSize">
                                        <template v-if="item.FileType == 2">
                                            {{ bytesToSize(item.FileSize) }}
                                        </template>
                                    </div>
                                    <div class="fileBtns">
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="重命名"
                                            placement="top"
                                        >
                                            <button
                                                v-if="buttonPermissions(item)"
                                                class="iconfont iconbianji"
                                                title="重命名"
                                                @click.stop="editFileClick(index)"
                                            ></button>
                                        </el-tooltip>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="上传"
                                            placement="top"
                                        >
                                            <button
                                                title="上传"
                                                v-if="!currentViewData.businessFileGroupConfig.IsRead &&
                                                    currentViewData.businessFileGroupConfig.IsAllowUpload &&
                                                    item.FileType == 1"
                                                @click.stop="uploadClick(item)"
                                            >
                                                <el-upload
                                                    multiple
                                                    action=""
                                                    :show-file-list="false"
                                                    :http-request="getfile"
                                                >
                                                    <i class="iconfont iconshangchuan1"></i>
                                                </el-upload>
                                            </button>
                                        </el-tooltip>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="下载"
                                            placement="top"
                                        >
                                            <button
                                                v-if="item.FileType == 2"
                                                class="iconfont iconxiazai2"
                                                title="下载"
                                                @click.stop="download(item)"
                                            ></button>
                                        </el-tooltip>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="预览"
                                            placement="top"
                                        >
                                            <button
                                                v-if="item.FileType == 2"
                                                class="iconfont iconhetongchaxun"
                                                title="预览"
                                                @click.stop="viewFileClick(item)"
                                            ></button>
                                        </el-tooltip>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="删除文件"
                                            placement="top"
                                        >
                                            <button
                                                v-if="
                                                    buttonPermissions(item) &&
                                                        currentViewData.businessFileGroupConfig.IsAllowDeleteFile &&
                                                        item.FileType == 2"
                                                class="iconfont iconshanchu"
                                                title="删除文件"
                                                @click.stop="deleteFileClick(item)"
                                            ></button>
                                        </el-tooltip>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="删除文件夹"
                                            placement="top"
                                        >
                                            <button
                                                v-if="
                                                    buttonPermissions(item) &&
                                                        currentViewData.businessFileGroupConfig.IsAllowDeleteFolder &&
                                                        item.FileType == 1"
                                                class="iconfont iconshanchu"
                                                title="删除文件夹"
                                                @click.stop="deleteFolderClick(item)"
                                            ></button>
                                        </el-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr class="noeAnnexData">
                                <td colspan="6">
                                    <p></p>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="dialog_box">
            <el-image-viewer
                v-if="showPreview"
                :url-list="previewImages"
                :on-close="closeViewer"
            ></el-image-viewer>
        </div>
    </div>
</template>

<script>
import '@/assets/css/annexStyle.styl';
// import uploading from '@/components/uploading/index.vue';
// import utils from '@/utils/util.js';
// 可自行去对应目录查看该组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
    // 流程附件
    name: 'annex-data',
    components: { ElImageViewer },
    props: {
        currentViewData: Object,
    },
    data() {
        return {
            buCode: 'Flow',
            showPreview: false,
            previewImages: [],
            // 操作项
            currData: null,
            businessIId: '',
            // 面包屑导航数据
            fileHierarchy: [],
            // 附件数据集合
            fileData: [],
            // 预览图片ID集合
            picturePreview: [],
            // 当前父ID 新增文件夹使用
            ParentID: 0,
            // 新增文件夹绑定数据
            newFile: {
                FileName: '',
                FileDescription: '',
            },
            filename: '',
            // 新增文件夹栏状态
            isAdd: false,
            // 当前父ID 上传附件使用，在文件夹上上传，和新增文件父id不一样
            currentFile: '0',
            // 在文件夹上上传,当前文件夹信息
            newFileinfo: {
                name: '',
                id: '',
            },
            // 上传文件加载中状态
            loading: false,
            // 文件上传进度条状态
            isProgress: false,
            // 文件上传进度
            progressPercent: 0,
            // 当前用户id
            Uid: this.$takeTokenParameters('Uid'),

            aliyunOssToken: '',
        };
    },
    async created() {
        this.loading = true;
        this.businessIId = this.currentViewData.businessInstanceId;
        // 初始化件数据
        this.fileData = this.initFileData(this.currentViewData.fileGroupInstanceList, this.currentFile);

        this.aliyunOssToken = await this.getOssToken();
    },
    watch: {
        // 加载附件数据
        'currentViewData.fileGroupInstanceList'(newVal) {
            this.fileData = this.initFileData(newVal, this.currentFile);
        },
    },
    methods: {
        imgFun() {
            return this.imgUrl;
        },
        // 弹窗取消
        handleClose() {
            this.dialogVisible = false;
        },
        // 文件大小转换
        bytesToSize(fileByte) {
            const fileSizeByte = fileByte;
            let fileSizeMsg = '';
            if (fileSizeByte < 1048576) {
                fileSizeMsg = (fileSizeByte / 1024).toFixed(1) + 'KB';
            } else if (fileSizeByte === 1048576) {
                fileSizeMsg = '1MB';
            } else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824) {
                fileSizeMsg = (fileSizeByte / (1024 * 1024)).toFixed(1) + 'MB';
            } else if (fileSizeByte > 1048576 && fileSizeByte === 1073741824) {
                fileSizeMsg = '1GB';
            } else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776) {
                fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(1) + 'GB';
            } else {
                fileSizeMsg = '文件超过1TB';
            }
            return fileSizeMsg;
        },
        // 根据id过滤附件数据
        initFileData(newVal, val, type) {
            const initFileData = [];
            if (newVal && newVal !== null) {
                newVal.forEach(item => {
                    if (item.ParentID === val) {
                        if (this.currentViewData.businessFileGroupConfig.IsModifyOwner) {
                            if (item.CreateId === this.$takeTokenParameters()) {
                                item.IsModifyOwner = true;
                            } else {
                                item.IsModifyOwner = false;
                            }
                        } else {
                            item.IsModifyOwner = true;
                        }
                        if (type === 'FileID' && item.FileType !== '1') {
                            initFileData.push(item.FileID);
                        } else {
                            initFileData.push(item);
                        }
                    }
                });
            }
            this.loading = false;
            return initFileData;
        },
        // 按钮权限
        buttonPermissions(data) {
            let isModifiable = false;
            if (this.currentViewData.businessFileGroupConfig.IsRead === false) {
                if (this.currentViewData.businessFileGroupConfig.IsModifyOwner === true) {
                    if (this.currentViewData.businessFileGroupConfig.IsModifyOwner && data.CreateId === this.Uid) {
                        isModifiable = true;
                    }
                } else if (this.currentViewData.businessFileGroupConfig.IsModifyOwner === false) {
                    isModifiable = true;
                }
            }
            return isModifiable;
        },
        // 重命名
        editFileClick(index) {
            this.$refs.content[index].contentEditable = true;
            this.$refs.content[index].focus();
        },
        // 失焦确定重命名
        preservationName(event, index, item) {
            this.fileData[index].FileName = event.target.innerText;
            this.$refs.content[index].contentEditable = false;
            const _that = this;
            this.$axios
                .put('/interfaceApi/basicplatform/simpleflow/filegroupinstance/filename', {
                    FileID: item.FileID,
                    EditFileName: event.target.innerText,
                })
                .then(res => {
                    if (res) {
                        this.$message({
                            message: '更改成功！',
                            type: 'success',
                        });
                    }
                })
                .catch(error => {
                    _that.$message.error(error.ErrorCode.Message);
                });
        },
        // 上传
        uploadClick(data) {
            this.currentFile = data.FileID;
            this.newFileinfo.name = data.FileName;
            this.newFileinfo.id = data.FileID;
            this.currData = data;
        },
        // 文件下载
        download(data) {
            if (data.preview_url) {
                const date = new Date();
                const endDate = new Date(data.expire);
                if (date.getTime() < endDate.getTime()) {
                    if (data.mime_type.indexOf('image/') === -1) {
                        window.location.href = data.download_url;
                    } else {
                        window.location.href = data.download_url;
                    }
                } else {
                    this.getFileDetailFun(data, 1);
                }
            } else {
                this.getFileDetailFun(data, 1);
            }
        },
        // 批量下载
        batchDownload() {
            this.currentViewData.fileGroupInstanceList.forEach(item => {
                if (item.FileType === '2' && item.AFI_Code) {
                    this.download(item);
                }
            });
        },
        //   预览
        viewFileClick(data) {
            if (data.preview_url) {
                const date = new Date();
                const endDate = new Date(data.expire);
                if (date.getTime() < endDate.getTime()) {
                    if (data.mime_type.indexOf('image/') === -1) {
                        window.open(data.preview_url, '_blank');
                    } else {
                        this.imgUrl = data.preview_url;
                        this.dialogVisible = true;
                        this.previewImages = [data.preview_url];
                        this.showPreview = true;
                    }
                } else {
                    this.getFileDetailFun(data, 2);
                }
            } else {
                this.getFileDetailFun(data, 2);
            }
        },
        // 获取文件下载预览地址
        getFileDetailFun(data, type) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + data.AFI_Code)
                .then(res => {
                    this.fileData.forEach(item => {
                        if (item.AFI_Code === data.AFI_Code) {
                            item.expire = res.expire;
                            item.mime_type = res.mime_type;
                            if (res.mime_type.indexOf('image/') === -1) {
                                item.preview_url = res.preview_url;
                                item.download_url = res.download_url;
                            } else {
                                item.preview_url = res.preview_url;
                                item.download_url = res.download_url;
                            }
                        }
                    });
                    if (type === 2) {
                        if (res.mime_type.indexOf('image/') === -1) {
                            window.open(res.preview_url, '_blank');
                        } else {
                            this.imgUrl = res.preview_url;
                            this.dialogVisible = true;
                        }
                        return;
                    }

                    if (res.mime_type.indexOf('image/') === -1) {
                        window.location.href = res.download_url;
                    } else {
                        window.location.href = res.download_url;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭预览
        closeViewer() {
            this.showPreview = false;
        },
        //   删除附件文件
        deleteFileClick(item) {
            this.deleteFile(item.FileID);
        },
        // 删除附件文件夹
        deleteFolderClick(item) {
            const deleteFileID = [];
            deleteFileID.push(item.FileID);
            deleteFileID.push(...this.initFileData(this.currentViewData.fileGroupInstanceList, item.FileID, 'FileID'));
            deleteFileID.forEach(item => {
                this.deleteFile(item);
            });
        },
        // 删除文件
        deleteFile(id) {
            const _that = this;
            this.$axios
                .delete('/interfaceApi/basicplatform/simpleflow/filegroupinstance/' + id, {})
                .then(res => {
                    if (res) {
                        this.$message({
                            message: '删除成功！',
                            type: 'success',
                        });
                    }
                    _that.currentViewData.fileGroupInstanceList.splice(
                        _that.currentViewData.fileGroupInstanceList.findIndex(v => v.FileID === id), 1
                    );
                })
                .catch(error => {
                    _that.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加
        AddFile() {
            this.isAdd = true;
        },
        // 确认添加
        confirmAdd() {
            if (this.newFile.FileName !== '') {
                const filename = this.newFile.FileName;
                this.filename = this.newFile.FileName;
                const date = new Date();
                const pUrl = this.fileHierarchy && this.fileHierarchy[0] ? this.fileHierarchy[this.fileHierarchy.length - 1].path : '';
                const formData = new FormData();
                // 注意formData里append添加的键的大小写
                formData.append('key', pUrl ? `${pUrl}/${filename}` : `${this.aliyunOssToken.dir}${this.businessIId}/${filename}`); // 存储在oss的文件路径
                formData.append('OSSAccessKeyId', this.aliyunOssToken.accessid); // accessKeyId
                formData.append('policy', this.aliyunOssToken.policy); // policy
                formData.append('Signature', this.aliyunOssToken.signature); // 签名
                // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                formData.append('file', filename);
                formData.append('success_action_status', 200); // 成功后返回的操作码
                formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码

                // 回调参数
                const obj = {
                    bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                    business_type_code: this.buCode, // 业务类型编码
                    object_name: pUrl ? `${pUrl}/${filename}` : `${this.aliyunOssToken.dir}${this.businessIId}/${filename}`, // 文件对象名
                    show_name: filename, // 显示文件名
                    size: 0, // 文件大小
                    mimeType: '',
                    data_id: this.businessIId, // 业务数据id
                    directory_id: this.ParentID ? this.ParentID : '', // 目录id
                    tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                };

                if (this.aliyunOssToken && this.aliyunOssToken.expire && this.aliyunOssToken.expire * 1000 > date.getTime()) {
                    this.$nextTick(() => {
                        this.upload(formData, obj);
                    });
                } else {
                    this.getOssToken();
                    this.$nextTick(() => {
                        this.upload(formData, obj);
                    });
                }
                this.isAdd = false;
                this.newFile = {
                    FileName: '',
                    FileDescription: '',
                };
            } else {
                this.$message('请填写文件名称！');
            }
        },
        // 取消添加
        cancelAdd() {
            this.isAdd = false;
            this.newFile = {
                FileName: '',
                FileDescription: '',
            };
            this.filename = '';
        },
        // 查看
        seeFile(data) {
            if (data.FileType !== 2 && data.FileType !== '2') {
                this.ParentID = data.FileID;
                this.currentFile = data.FileID;
                this.currData = data;
                const newFileData = [];
                (this.currentViewData.fileGroupInstanceList || []).forEach(item => {
                    if (item.ParentID === data.FileID) {
                        newFileData.push(item);
                    }
                });
                this.fileData = newFileData;
                this.fileHierarchy.push(
                    {
                        data: data.FileName,
                        id: data.FileID,
                        ...data,
                    }
                );
            }
        },
        // 返回顶层
        backTop() {
            this.fileData = this.initFileData(this.currentViewData.fileGroupInstanceList, '0');
            this.ParentID = '0';
            this.currentFile = '0';
            this.fileHierarchy = [];
        },
        // 返回上级目录
        returnDirectory(id, index, data) {
            this.ParentID = data.FileID;
            this.currentFile = data.FileID;
            this.currData = data;
            this.fileData = this.initFileData(this.currentViewData.fileGroupInstanceList, id);
            this.fileHierarchy.splice(index + 1, this.fileHierarchy.length - index);
        },
        // 获取文件
        async getfile(e) {
            const date = new Date();
            if (!this.aliyunOssToken?.expire || this.aliyunOssToken.expire * 1000 < date.getTime()) {
                this.aliyunOssToken = await this.getOssToken();
            }
            this.$nextTick(() => {
                this.file = e.file;
                this.filename = this.file.name;
                // 判断是否过期，过期重新调用获取通信证方法
                const formData = new FormData();
                // eslint-disable-next-line max-len
                let pUrl = this.currData ? `${this.aliyunOssToken.dir}${this.currData.KeyID}/${this.currData.FileName}/` : `${this.aliyunOssToken.dir}${this.businessIId}/`;
                if (this.fileHierarchy && this.fileHierarchy[0]) {
                    this.fileHierarchy.forEach(item => {
                        if (item.data) {
                            pUrl += `${item.data}/`;
                        }
                    });
                }
                // 注意formData里append添加的键的大小写
                formData.append('key', pUrl + e.file.name); // 存储在oss的文件路径
                formData.append('OSSAccessKeyId', this.aliyunOssToken.accessid); // accessKeyId
                formData.append('policy', this.aliyunOssToken.policy); // policy
                formData.append('Signature', this.aliyunOssToken.signature); // 签名
                // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                formData.append('file', this.file);
                formData.append('success_action_status', 200); // 成功后返回的操作码
                formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码
                // 图片地址
                this.fileUrl = this.aliyunOssToken.host + '/' + this.aliyunOssToken.dir + e.file.name;
                this.upload(formData, null, e);
            });
        },
        // 上传文件
        upload(formData, val, e) {
            const _that = this;
            this.progressPercent = 0;
            let pUrl = this.currData ? `${this.currData.path}/` : `${this.aliyunOssToken.dir}${this.businessIId}/`;
            if (this.fileHierarchy && this.fileHierarchy[0]) {
                this.fileHierarchy.forEach(item => {
                    if (item.data) {
                        pUrl += `${item.data}/`;
                    }
                });
            }
            $.ajax({
                url: this.aliyunOssToken.host,
                type: 'POST',
                data: formData,
                cache: false, // 不缓存数据
                processData: false, // 不处理数据
                contentType: false, // 不设置内容类型
                success: () => { // 成功回调
                    this.isProgress = false;
                    const file = val ? '' : e.file;
                    const obj = val ? val : {
                        bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                        business_type_code: this.buCode, // 业务类型编码
                        object_name: pUrl + e.file.name, // 文件对象名
                        show_name: e.file.name, // 显示文件名
                        size: file.size, // 文件大小
                        mimeType: file.type,
                        mime_type: file.type,
                        data_id: this.businessIId, // 业务数据id
                        directory_id: this.parentid ? this.parentid : '', // 目录id
                        tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                    };
                    this.callBackFun(obj, val, e);
                },
                xhr: function () {
                    const myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) { // 检查upload属性是否存在
                        myXhr.upload.addEventListener('progress', _that.progressHandlingFunction, false); // 绑定progress事件的回调函数
                    }
                    return myXhr; // xhr对象返回给jQuery使用
                },
            });
        },
        // 上传进度
        progressHandlingFunction(event) {
            this.isProgress = true;
            this.progressPercent = Math.floor(100 * (event.loaded / event.total));
        },
        // 上传的回调
        callBackFun(obj, val, e) {
            const num = val ? 1 : 2;
            const file = e ? e.file : null;
            this.$axios.post(this.FILE.FILE_BASE_URL_OSS() + 'api/UploadCallback', obj).then(res => {
                this.uploadFirm(res.id, file, num);
            });
        },
        // 上传成功，添加文件
        uploadFirm(AFI_Code, fileData, num) {
            this.videoFlag = false;
            this.videoUploadPercent = 0;
            if (this.currentFile) {
                const _that = this;
                this.$axios
                    .post('/interfaceApi/basicplatform/simpleflow/filegroupinstance', {
                        FileID: '',
                        FileName: fileData ? fileData.name : this.filename,
                        FileExt: '',
                        FileSize: fileData ? fileData.size : 0,
                        FileType: num,
                        FileSort: 0,
                        FileDescription: fileData ? '' : this.newFile.FileDescription,
                        ParentID: this.currentFile,
                        BusinessType: '',
                        KeyID: this.currentViewData.businessInstanceId,
                        AFI_Code: AFI_Code,
                    })
                    .then(res => {
                        if (res) {
                            _that.currentViewData.fileGroupInstanceList.push(res);
                            if (_that.fileHierarchy.length === 0 || _that.fileHierarchy[_that.fileHierarchy.length - 1].id !== this.newFileinfo.id) {
                                _that.fileHierarchy.push(
                                    {
                                        data: this.newFileinfo.name,
                                        id: this.newFileinfo.id,
                                        ...res,
                                    }
                                );
                            }
                            this.filename = '';
                            this.isProgress = false;
                        }
                    })
                    .catch(error => {
                        _that.$message.error(error.message);
                        this.isProgress = false;
                    });
            }
        },

        // 获取上传通行证
        getOssToken() {
            const path = `${this.FILE.FILE_BASE_URL_OSS()}credential/GetPostPolicy/${this.buCode}`;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>

<style lang="stylus" >
.procedureAnnex
    height calc(100% - 0.42rem)
    padding 0 !important
    .annexDataHeader
        height 0.84rem
        background #FAFBFD
        nav
            padding 0 0.2rem
            height 0.52rem
            margin 0
            display flex
            align-items center
            a
                cursor: pointer;
                font-size 0.14rem
                color #0078FB
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
            span
                font-size 0.14rem
                color #999999
        .operationArea
            display flex
            justify-content space-between
            align-items center
            padding-left 0.2rem
            background #F3F9FF
            .fileBtn
                display flex
                .upload_box
                    margin 0
                    width 0.4rem
                button,.upload_btn
                    flex 0 0 0.4rem
                    width 0.4rem
                    height 0.28rem
                    padding 0
                    line-height 0.28rem
                    font-size 0.22rem
                    color rgba(0, 0, 0, 0.5)
                    border none
                    background none
                    text-align center
                    i
                        font-size 0.22rem
                        color rgba(0, 0, 0, 0.5)
            .plannedSpeed
                width calc(100% - 1.5rem)
    .annexList
        height calc(100% - 0.84rem)
        overflow-y auto
        table
            width 100%
            border-collapse collapse
            thead
                .tebleFome
                    th,td
                        height 0.5rem
                    td
                        >div
                            display flex
                            input
                                width calc(100% - 1rem)
                                height 0.36rem
                                border 1px solid #D7D7D7
                                padding-left 0.14rem
                            button
                                width 0.4rem
                                margin-left 0.1rem
                                border none
                                background none
                                font-size 0.2rem
                                color rgba(0, 0, 0, 0.5)
            tr
                &.noeAnnexData
                    p
                        height 5rem
                        background url(../images/noData.png) center no-repeat
                        background-size 80%
                &:not(.noeAnnexData)
                    border-bottom 1px solid #F0F0F0
                    &:hover
                        background #F7FAFD
                        .fileBtns
                            visibility visible
            th,td
                height 0.8rem
            td
                &:last-child
                    padding-right 0.2rem
            .iconwenjianjia1
                width 0.36rem
                height 0.32rem
                vertical-align -0.15em
                fill currentColor
                overflow hidden
            .iconwenjian
                width 0.32rem
                height 0.4rem
                vertical-align -0.15em
                fill currentColor
                overflow hidden
            .fileName
                width 2rem
                overflow hidden
                white-space nowrap
                text-overflow ellipsis
                margin-bottom 0.05rem
                font-size 0.16rem
                height 0.36rem
                line-height 0.36rem
                color #333333
                &:focus,
                &:focus-visible
                    line-height 0.34rem
                    border 1px solid #D7D7D7
                    padding-left 0.1rem
            .fileTime,.fileSize
                font-size 0.12rem
                line-height 0.18rem
                color #999999
            .fileSize
                text-align right
                margin-bottom 0.1rem
            .fileBtns
                visibility hidden
                display flex
                justify-content flex-end
                .upload_box
                    margin 0
                    width 0.35rem
                button,.upload_btn
                    flex 0 0 0.35rem
                    width 0.35rem
                    height 0.28rem
                    padding 0
                    line-height 0.28rem
                    font-size 0.22rem
                    color #666666
                    border none
                    background none
                    cursor pointer
                    i
                        font-size 0.22rem
                    &:hover
                        color #0078FB
    .img_url
        width 100%
        height 100%
</style>
